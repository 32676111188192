








































import Vue from 'vue';
interface TypeInfo {
  typeText: string;
  tipText: string;
  iconType: string;
  iconColor: string;
}
export default Vue.extend({
  name: 'reconfirm',
  props: {
    type: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    tipMsg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputText: '',
      typeInfo: {} as TypeInfo,
    };
  },
  watch: {
    visible: function (val) {
      if (val) {
        this.inputText = '';
      }
    },
    type: {
      handler: function (val, oldVal) {
        if (val && val !== oldVal) {
          this.setTextInit(val);
        }
      },
      immediate: true,
    },
  },
  computed: {
    iconStyle(): Record<string, string> {
      const color = this.typeInfo?.iconColor;
      const iconStyle = {
        fontSize: '60px',
        color,
      };
      return iconStyle;
    },
  },
  methods: {
    setTextInit(type: string) {
      const typeObj = {
        open: {
          typeText: '启用',
          tipText: '启用之后在产品管理处可配置',
          iconType: 'check-circle',
          iconColor: '#52c41a',
        },
        disable: {
          typeText: '禁用',
          tipText: '禁用之后在产品管理处不可配置',
          iconType: 'question-circle',
          iconColor: 'rgb(255,132, 54)',
        },
        delete: {
          typeText: '删除',
          tipText: '删除之后不可恢复',
          iconType: 'exclamation-circle',
          iconColor: '#f64042',
        },
      };
      this.typeInfo = typeObj[type];
    },
    ok() {
      if (this.inputText === '')
        return this.$message.warning('输入内容不能为空');
      if (this.inputText !== this.typeInfo?.typeText)
        return this.$message.warning('输入内容不一致');
      this.$emit('ok');
    },
    close() {
      this.$emit('update:visible', false);
    },
  },
});
