import { render, staticRenderFns } from "./reconfirm.vue?vue&type=template&id=c76c27d4&scoped=true&"
import script from "./reconfirm.vue?vue&type=script&lang=ts&"
export * from "./reconfirm.vue?vue&type=script&lang=ts&"
import style0 from "./reconfirm.vue?vue&type=style&index=0&id=c76c27d4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c76c27d4",
  null
  
)

export default component.exports